// Hat-tip:
// https://github.com/streamich/react-use/blob/master/src/useGetSetState.ts.
//
// `react-use` appears to be unmaintained, so moving the required code into
// this project for now.
import { useCallback, useRef, useState } from 'react';
const incrementParameter = (num) => ++num % 1_000_000;
const useUpdate = () => {
    const [, setState] = useState(0);
    return useCallback(() => setState(incrementParameter), []);
};
export const useGetSetState = (
/* istanbul ignore next */
initialState = {}) => {
    const update = useUpdate();
    const state = useRef({ ...initialState });
    const get = useCallback(() => state.current, []);
    const set = useCallback((patch) => {
        if (!patch) {
            return;
        }
        Object.assign(state.current, patch);
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [get, set];
};
